import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Button } from "react-bootstrap"
import { Link } from "gatsby"
import {
  FaArrowRight,
  FaConnectdevelop,
  FaCloud,
  FaDna,
  FaBuromobelexperte,
} from "react-icons/fa"
import { BsFillChatSquareQuoteFill } from "react-icons/bs"
import SimpleSlider from "../components/carousel"
import WaitlistModal from "../components/modal"
import CommunityForm from "../components/forms/communityform"
import { useWindowWidth } from "../utils/useWindowWidth"

// Images
const tennessee = require("../images/tennessee.png")
const austinLogo = require("../images/austin.png")
const kaistLogo = require("../images/kaist.png")
const washingtonLogo = require("../images/washington.png")
const floridaLogo = require("../images/florida.png")
const intelLogo = require("../images/intel.png")
const amdLogo = require("../images/amd.png")
const hpeLogo = require("../images/hpe.png")
const dellEMCLogo = require("../images/dell.png")
const testimonial1 = require("../images/testimonial1.png")
const testimonial2 = require("../images/testimonial2.png")
const testimonial3 = require("../images/testimonial3.png")
const dataCloud = require("../images/data-cloud.jpeg")

const slides = [
  <div className="testimonial-slide">
    <div className="slide-text">
      <div className="quote-icon">
        <BsFillChatSquareQuoteFill />
      </div>
      <h3>"Flapmax gave me confidence in learning new technologies."</h3>
      <div className="main-body w-link">
        <p>
          "I would like to thank you for the opportunity to be an intern at
          Flapmax. It has been a great learning to be a part of diverse projects
          as a Full Stack Developer, participate in a Global hackathon for a
          sponsor challenge and be part of various meeting sessions with
          prospective clients. As a programmer, I could for the first time
          explore the technologies Docker, Django framework, Redis and Apache
          Arrow. This has instilled confidence in me to learn new things and
          build project modules."
        </p>
        <p className="text-center name">- Srividya</p>
        <a
          href="https://blog.flapmax.com/articles/flapmax-interns-finalist-global-hackathon/"
          target="_blank"
        >
          Read More About Srividya's Story
        </a>
      </div>
    </div>
    <div className="slide-img">
      <img src={testimonial1} alt="First slide" />
    </div>
  </div>,
  <div className="testimonial-slide">
    <div className="slide-text">
      <div className="quote-icon">
        <BsFillChatSquareQuoteFill />
      </div>
      <h3>"I would love to help Flapmax as it helped me grow."</h3>

      <div className="main-body">
        <p>
          "I had a great learning experience and saw a good growth in me as I
          completed my internship. Although I am not currently working with you
          but would love to help with my project anytime required. Let me know
          if any assistance is needed. I would love to help Flapmax as it helped
          me grow. Once again thank you so much for everything."
        </p>
      </div>
      <p className="text-center name">- Gurpreet</p>
    </div>
    <div className="slide-img">
      <img src={testimonial2} alt="First slide" />
    </div>
  </div>,
  <div className="testimonial-slide">
    <div className="slide-text">
      <div className="quote-icon">
        <BsFillChatSquareQuoteFill />
      </div>
      <h3>"I'm grateful to be a part of this organization."</h3>

      <div className="main-body">
        <p>
          "My time spent working with Flapmax motivated me to challenge myself
          both technically and professionally while also acting as a bridge
          between academia and industry. During my internship, having the
          hands-on experience by working on a project that addressed
          contemporary problems not only increased my interest in topics I was
          mostly unfamiliar with but also gave me the perspective I needed to
          make a decision about where I was and where I wanted to be heading.
          I'm grateful to be a part of this organization."
        </p>
      </div>
      <p className="text-center name">- Jacob</p>
    </div>
    <div className="slide-img">
      <img src={testimonial3} alt="First slide" />
    </div>
  </div>,
]

const IndexPage = () => {
  const [waitlistDisplay, setWaitlistDisplay] = useState(false)
  const [communityFormDisplay, setCommunityFormDisplay] = useState(false)
  const windowWidth = useWindowWidth()
  return (
    <Layout>
      {communityFormDisplay && (
        <CommunityForm setCommunityFormDisplay={setCommunityFormDisplay} />
      )}
      {waitlistDisplay && (
        <WaitlistModal setWaitlistDisplay={setWaitlistDisplay} />
      )}
      <SEO title="Home" description="" lang="en" meta={[]} />

      {/* Hero */}
      <div className="banner-image">
        <div className="hero-text">
          <h1>University-quality research.</h1>
          <h1>Industry-scale impact.</h1>
        </div>
        <div className="banner-filter-bg" />
      </div>

      {/* Learning Section */}
      <section className="learning-practice bg-darkened">
        <div className="container">
          <h2 className="mb-5 text-center">
            Flapmax University Alliance is fostering an environment for
            experiential learning, bringing academia and industry closer
            together
          </h2>
          <div className="row">
            <div className="col-lg learning-cards">
              <div className="img-container">
                <h3>Educator</h3>

                <div className="img-div one"></div>
              </div>
              <h5>
                Helps university faculty provide students with additional skills
              </h5>
              <p>
                Flapmax actively collaborates with university faculty, post-doc,
                and other educators to address important technical challenges
                facing our society today.
              </p>
              <Link to="/educator" className="d-block w-100">
                <Button variant="secondary" className="d-block w-100">
                  Learn More
                </Button>
              </Link>
            </div>

            <div className="col-lg  learning-cards">
              <div className="img-container">
                <h3>Student</h3>

                <div className="img-div two"></div>
              </div>
              <h5>Students work on the latest technology</h5>
              <p>
                Through a variety of programs designed to encourage and mentor
                students, Flapmax supports promising students seeking to make an
                impact in the world.
              </p>
              <Link to="/student" className="d-block w-100">
                <Button variant="secondary" className="d-block w-100">
                  Learn More
                </Button>
              </Link>
            </div>

            <div className="col-lg learning-cards">
              <div className="img-container">
                <h3>Ecosystem</h3>

                <div className="img-div three"></div>
              </div>
              <h5>Access to developer tools and cloud services</h5>
              <p>
                We partner with companies that align strategically with our
                mission of promoting research and innovation while making
                industry-wide impact.
              </p>
              <Link to="/industry" className="d-block">
                <Button variant="secondary" className="d-block w-100">
                  Learn More
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Affiliates */}
      <section className="affiliate-section">
        <div className="container">
          <div className="row">
            <h2 className="text-center mb-5">Our Collaborators</h2>
          </div>
          <div className="row affiliates">
            <div className="col">
              <img
                src={tennessee}
                alt="Tennessee"
                className="img-fluid d-block"
              />
            </div>
            <div className="col">
              <img
                src={austinLogo}
                alt="Austin"
                className="img-fluid d-block"
              />
            </div>
            <div className="col">
              <img src={kaistLogo} alt="KAIST" className="img-fluid d-block" />
            </div>
            <div className="col">
              <img
                src={washingtonLogo}
                alt="Washington"
                className="img-fluid d-block"
              />
            </div>
            <div className="col">
              <img
                src={floridaLogo}
                alt="Florida"
                className="img-fluid d-block"
              />
            </div>
          </div>

          <div className="row affiliates mt-5">
            <div className="col">
              <img src={intelLogo} alt="Intel" className="img-fluid d-block" />
            </div>
            <div className="col">
              <img src={amdLogo} alt="Amd" className="img-fluid d-block" />
            </div>
            <div className="col">
              <img
                src={dellEMCLogo}
                alt="Dell EMC"
                className="img-fluid d-block"
              />
            </div>
            <div className="col">
              <img
                src={hpeLogo}
                alt="Hewlett Packard Enterprise"
                className="img-fluid d-block"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Focus Areas */}
      <section className="accordion-bg">
        <div className="container accordion">
          <div className="row">
            <h1 className="mb-3">
              Supporting exciting and innovating research through Applied AI
            </h1>
          </div>
          <div className="row spacer-row">
            <h3>
              Bring your most complicated applications and let Flapmax deliver
              through one or more of our principal research thrusts in
              collaboration with our academic partners. 
            </h3>
          </div>
          <div className="row mt-5 d-flex  justify-content-between align-items-start">
            <div className="col-lg-6 pt-3">
              <img alt="" className="img-fluid" src={dataCloud} />
            </div>

            <div className="col-lg-6">
              <h2>Focus Areas</h2>
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      Data
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      As with all 4 of our focus areas, data is fundamental to
                      Applied AI, enabling key insights through the application
                      of advanced machine learning algorithms in areas such as
                      data mining, data integration, graph analytics, data
                      science, and data management.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      Scientific ML Applications
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      With the rapid increase in artificial intelligence
                      technologies in many science and engineering domains such
                      as computer vision, language understanding, and
                      recommender systems, HPC applications are beginning to
                      adopt AI for a variety of scientific problems including
                      weather &amp; climate studies, high-energy physics,
                      bioinformatics, and computational chemistry.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      System Software
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      From sensors and IoT devices to edge processing and cloud
                      management and storage, disparate systems (including both
                      hardware and software) must interoperate seamlessly to
                      solve highly complex tasks within tolerable limits like
                      latency, bandwidth and power while delivering rich
                      experiences for users.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                      Hardware
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingFour"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Heterogeneous computing hardware will play an important
                      role in next-gen mission critical systems. Combining Data,
                      SciML and Software together, hardware needs to evolve to
                      support fast changing demands in industry, including areas
                      such as hardware acceleration, 5G platforms,
                      reconfigurable computing and SoC.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 d-flex justify-content-center">
            <Link to="/join">
              <Button size="lg" variant="primary">
                Work With Us
              </Button>
            </Link>
          </div>
        </div>
      </section>

      {/* Community Section */}
      <section className="bg-darkened community-devtools">
        <div className={"container-lg"}>
          <div className={"row justify-content-around"}>
            <div className="col-lg-4">
              <h2>Community</h2>

              <p>
                Connect and collaborate with others. Brainstorm and solve
                problems together. Propose new challenges or even find your next
                hackathon teammates!
              </p>

              <div className="d-flex justify-content-center">
                <Button
                  variant="info"
                  as="a"
                  style={{ color: "#242424" }}
                  target="_blank"
                  href="https://community.flapmax.com"
                >
                  Community Page
                </Button>
              </div>
            </div>
            <div className="col-lg-4">
              <h2>Developer Tools</h2>
              <p>
                Start building with our fast, secure, and flexible platforms.
              </p>
              <ul>
                <li>Open Source</li>
                <li>Data Science</li>
                <li>Developer Cloud</li>
              </ul>

              <div className="d-flex justify-content-center">
                <Button
                  variant="info"
                  as="a"
                  href="https://developer.flapmax.com/"
                >
                  Developer Page
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Technology Section */}
      <section className="technology-section bg-darkened">
        <div className="container">
          <div className="row">
            <div className="col-lg tech-one">
              <div className="tech-header">
                <FaConnectdevelop />
                <h3 className="text-center">Discovery in Open Source</h3>
              </div>
              <p>
                At a principii of AI integration &amp; revolution today, where
                all becomes available and here we help find your fit. By example
                and through result, consistently delivering results and making
                this your freedom to explore.
              </p>
              <div className="tech-footer">
                <a href="https://developer.flapmax.com" target="_blank">
                  <FaArrowRight />
                </a>
              </div>
            </div>
            <div className="col-lg tech-two">
              <div className="tech-header">
                <FaCloud />
                <h3 className="text-center">Hybrid Cloud</h3>
              </div>
              <p>
                Integration of your needs and solution online, sharing the
                resources and compute to maximizing your returns and minimize
                the cost.
              </p>
              <div className="tech-footer">
                <Link to="/hybridcloud">
                  <FaArrowRight />
                </Link>
              </div>
            </div>
            <div className="col-lg tech-three">
              <div className="tech-header">
                <FaDna />
                <h3 className="text-center">Data Science and Analytics</h3>
              </div>
              <p>
                Bringing your team to the data, taking Flapmax's established
                portfolio and clean presentation to generate, assess and
                demonstrate complex models.
              </p>
              <div
                className="tech-footer"
                onClick={() => setWaitlistDisplay(true)}
              >
                <FaArrowRight />
              </div>
            </div>
            <div className="col-lg tech-four">
              <div className="tech-header">
                <FaBuromobelexperte />
                <h3 className="text-center">Internet of Things</h3>
                <h3 className="text-center">(IoT &amp; 5G)</h3>
              </div>
              <p>
                IoT begins the data, now we give you the tools to deliver it,
                with powerful insight &amp; adaptive analytics, bringing your
                data to life.
              </p>
              <div className="tech-footer">
                <a
                  href="https://blog.flapmax.com/articles/flapmax-interns-finalist-global-hackathon/"
                  target="_blank"
                >
                  <FaArrowRight />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Flapmax Africa Section */}
      <section className="africa-section">
        <div className="africa-bg" />
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2>Flapmax @Africa</h2>
              <h3>
                Working with regional universities to establish long-term
                partnerships.
              </h3>

              <h4>
                In 2050 Africa will be the most populated continent and
                technology is growing fast.
              </h4>

              <h4>
                Flapmax is dedicated to Africa as a hub for innovation. We are
                rapidly building our collaboration pipeline by developing AI
                supported programs in Africa for areas such as healthcare,
                agriculture, education and more.
              </h4>

              <a
                href="https://musing-meninsky-ba17bf.netlify.app/africa/"
                target="_blank"
              >
                <Button variant="secondary">Learn More</Button>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonial Section */}
      <section className="bg-darkened">
        <div className="container">
          <div className="row">
            <div className="col subhero">
              <h2 className="mb-5">
                At Flapmax UA students, scientists, and technologists engage in
                collaborative projects that promote innovation while making
                meaningful impact worldwide
              </h2>
            </div>
          </div>
        </div>

        <div className="carousel-section bg-darkened">
          <SimpleSlider children={slides} />
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
